import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

export default function Index() {
  return (
    <Layout pageTitle="Torie Jee | Producer, Marketing Strategist | Brand Marketer">
      <StaticImage
        src="../images/torie-jee-crop.png"
        alt="Torie Jee"
        width={150}
        height={150}
        layout="fixed"
        className="rounded-circle my-3 mx-auto"
        quality="100"
      />
      <h2 className="text-center">
        Producer, <span className="text-nowrap">Marketing Strategist</span>,{" "}
        <span className="text-nowrap">Brand Marketer</span>
      </h2>
      <p>
        From reading scripts to developing fully realized 360 marketing
        campaigns, my world revolves around storytelling.{" "}
      </p>
      <p>
        In my past role at 20th Century Fox, I worked alongside producers and
        filmmakers to create content pieces that told the stories of how their
        film came to be, uncovering the secrets and details that made their
        movies truly special.
      </p>
      <p>
        In my position advertising for ABC Entertainment and The Walt Disney
        Studios at OMG23, I became well-versed in working with publishers to
        create short-form content that drew viewers in less than thirty seconds!
      </p>
      <p>
        Now, in my current position at The Walt Disney company, I work alongside
        executive producers and studio executives to ensure brand/show
        integrity, while also developing 360 domestic and international
        marketing campaigns that captivate audiences and get them to tune in.
      </p>
      <p>
        Whether it's action-packed stunt pieces or funny bite-sized videos, I
        strive to make everything I work on an exciting and new viewing
        experience!
      </p>
    </Layout>
  );
}
